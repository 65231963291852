import React from "react"
// import { Link } from "gatsby"
// import { Helmet } from "react-helmet"

import "../styles/tailwind.css"

const Layout = ({ location, title, children }) => {
  return (
    <div className="antialiased flex flex-col justify-between min-h-screen bg-white text-gray-900 font-sans">
      {/* <header className="">
        <div className="container">
          <h3 className="">
            <Link
              className="uppercase font-objektiv-mk1 text-2xl tracking-wide font-bold"
              to={`/`}
            >
              {title}
            </Link>
          </h3>
        </div>
      </header> */}

      <main className="container flex flex-col items-center flex-grow px-8 sm:py-16">
        {children}
      </main>

      {/* <footer className="bg-black py-4 text-white">
        <div className="container mx-auto">
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </div>
      </footer> */}
    </div>
  )
}

export default Layout
